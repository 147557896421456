function ConsumerBase({brand_string, logos, form_url,
                          service_date="5/31/23",
                          submission_date ="6/30/23"}) {
    return(
    <div className="main bg-stone-200 h-full md:px-20 px-5 pb-20 flex flex-col items-center">
        <div className="md:text-4xl text-2xl font-bold py-2.5">Home Steam Humidifier Service Reimbursement Program </div>

        <div className="flex lg:flex-row flex-col-reverse content-center pt-5">
            <div className="m-auto lg:w-1/2 text-lg">

                <p className="pt-5">
                    Per the terms and conditions of this program, the submission period for this program closed as of 7/1/23. <a
                    target="_" href="terms/">Terms and Conditions</a>.

                </p>

                <p className="pt-5">
                    <strong>IMPORTANT SAFETY RECALL ALERT:</strong> Research Products Corporation has determined a specific type of electrode wire with a “D” shaped connector may loosen causing arcing and/or overheating at the interface of the connector and canister, posing a fire hazard. The affected Steam Humidifiers were produced from June 2010 – January 2015. No other products are affected. Research Products Corporation voluntarily notified and is working with the U.S. Consumer Products Safety Commission on this issue.
                    To learn about the recall, please go to <a href="https://www.steamhumidifier.rpcrecall.com/" target="_"> RPCRecall.com/SteamHumidifier </a> or call  <a href="tel:1-888-742-2401">1.888.742.2401</a> (M-F 8 AM – 5 PM EST) to speak to a customer service agent.

                </p>
            </div>
            <div className="mx-5 lg:w-1/2 lg:p-0 m-auto pt-5 pb-10 flex flex-col">
                {
                    logos.map(
                        (logo) =>
                            (
                                <img alt={logo.alt} className="m-auto lg:max-w-md lg:py-10 max-w-xs sm:max-w-md  py-2.5" src={logo.logoPath}/>
                            )
                    )
                }
            </div>
        </div>
    </div>
    );
}
export default ConsumerBase;