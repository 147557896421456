import ContractorInHomeBase from "../contractorInHomeBase";

function JohnsonContractorInHome() {
    return (
        <div className="lg:h-screen h-full">
            <ContractorInHomeBase
                logos={[
                    {
                        logoPath:  '/johnson-controls.png',
                        alt: 'Johnson Controls Logo'
                    },
                ]}
                form_url="https://datarebate.com/johnsoncontrols-contractor/"
            />
        </div>
    )
}

export default JohnsonContractorInHome;