import React from 'react';
import ReactDOM from 'react-dom/client';

import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";


import './index.css';
import reportWebVitals from './reportWebVitals';
import Main from "./main";
import JohnsonTermsAndConditions from "./pages/johnson/johnsonTermsAndConditions";
import JohnsonConsumer from "./pages/johnson/johnsonConsumer";
import BryantCarrierConsumer from "./pages/bryant-carrier/bcConsumer";
import JohnsonContractorInHome from "./pages/johnson/johnsonContractorInHome";
import JohnsonContractorTermsAndConditions from "./pages/johnson/johnsonContractorTermsAndCondtions";
import BcContractorInHome from "./pages/bryant-carrier/bcContractorInHome";
import BryantCarrierTermsAndConditions from "./pages/bryant-carrier/bcTermsAndConditions";
import BryantCarrierContractorTermsAndCondition from "./pages/bryant-carrier/bcContractorTermsAndConditions";
import RecallHome from "./pages/rpcrecall/recallHome";


const router = createBrowserRouter(
    [
        {
            path:"/",
            element: <Main/>
        },
    ]
)

const jciRouter = createBrowserRouter(
    [
        {
            path:"/",
            element: <JohnsonConsumer/>
        },
        {
            path:"/terms",
            element: <JohnsonTermsAndConditions/>
        },
        {
            path:"/contractor/in-home/",
            element: <JohnsonContractorInHome/>
        },
        {
            path:"/contractor/in-home/terms",
            element:<JohnsonContractorTermsAndConditions/>
        }
    ]
)

const bryantCarrierRouter = createBrowserRouter(
    [
        {
            path:"/",
            element: <BryantCarrierConsumer/>
        },
        {
            path:"/terms",
            element: <BryantCarrierTermsAndConditions/>
        },
        {
            path:"/contractor/in-home",
            element: <BcContractorInHome/>
        },
        {
            path:"/contractor/in-home/terms",
            element: <BryantCarrierContractorTermsAndCondition/>
        }
    ]
)

const rpcRecallRouter = createBrowserRouter([
    {
        path:"/",
        element:<RecallHome/>
    }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));

const urlSplit= window.location.host.split(".");
const subDomain= urlSplit[0];
const domain = urlSplit[1];


if (domain === "humidifiersafety" || subDomain === "humidifiersafety" || subDomain) {

    if (subDomain === "jci") {
        root.render(
            <React.StrictMode>
                <RouterProvider router={jciRouter}/>
            </React.StrictMode>
        );
    } else if (subDomain === "rpc") {
        document.getElementById('fav').href = process.env.PUBLIC_URL + "/carrier.ico"
        root.render(
            <React.StrictMode>
                <RouterProvider router={bryantCarrierRouter}/>
            </React.StrictMode>
        );
    } else {
        document.getElementById('fav').href = ""
        root.render(
            <React.StrictMode>
                <RouterProvider router={router}/>
            </React.StrictMode>
        );
    }
}
else if (domain === "rpcrecall" || subDomain === "rpcrecall") {
    document.getElementById('fav').href = ""
    root.render(
      <React.StrictMode>
          <RouterProvider router={rpcRecallRouter} />
      </React.StrictMode>
    );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
