function TermsAndConditionsBase({brand_string,
                                    service_date = "5/31/23",
                                    submission_date = "6/30/23",
                                    processing_date_string="June 30, 2023"
                                }) {
    return (
        <div className="terms flex items-center justify-center h-max scroll-auto bg-stone-200 pb-20">
            <div className="content lg:w-3/6 w-5/6 py-20 lg:py-10">
                <p className="pb-2">
                    <span className="font-bold"> TERMS AND CONDITIONS: </span>
                    This reimbursement program applies to the service maintenance of
                    the {brand_string} HUMXXSTM3034
                    Steam Humidifier including but not limited to:
                </p>
                <ul className="list-disc list-inside pl-5 pb-5">
                    {
                        [
                            'Inspecting the steam humidifier.',
                            'Inspecting connector electrode wires and replacing as needed.',
                            'Replacing the steam canister if service light is illuminated or canister has been in service over 12 months. Replacement of canister is at consumer’s expense.'
                        ].map((content) =>(
                            <li className="p-1"> {content} </li>
                        ))
                    }
                </ul>
                <p className="pb-2">This offer is valid for homeowners in the United States & Canada. Fill out the online form completely, and submit it along with:</p>
                <ul className="list-decimal list-inside pl-5 pb-5">
                    <li className="p-1">
                        <strong> A valid paid invoice for service work provided including inspection of
                            humidifier and replacement of electrode wires. </strong>
                    </li>
                    <li className="p-1">
                        Service must be completed by <strong>{service_date}</strong> to be eligible. Reimbursement submissions must be submitted online on or before <strong>{submission_date}</strong>.
                    </li>
                </ul>

                <p className="pb-5">
                    This offer cannot be combined with any other incentive. Offer is not transferable. <strong> Duplicate invoices will be rejected.</strong> No substitutions or extensions. Offer is subject to product availability. We and our agents have the right to substantiate submissions and to reject claims that do not comply with these terms. We are not responsible for lost, stolen, invalid or incomplete submissions. In the event a customer has paid less for the product than the speciﬁed reimbursement amount, customer will only be entitled to receive reimbursement for the actual amount paid by customer for the product before taxes. Claims will not be accepted if receipt shows contractor deducted the incentive amount at the time of purchase.  We reserve the right to modify this incentive program at any time. This offer will not accept further processing activity after {processing_date_string}. Fraudulent submissions could result in federal prosecution under mail fraud statutes (Title 18 USC Sections 1341 & 1342).
                </p>
                <p className="pb-5">
                    Please allow 6-8 weeks for Visa prepaid card delivery after reimbursement documents have been received, processed and approved by <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                                                                                                                                         href="https://www.datarebate.com">www.datarebate.com</a>.
                    <span className="font-bold"> For information or questions regarding the status of your reimbursement claim, go to <a
                        href="https://www.datarebate.com">www.datarebate.com</a>, email us at <a
                        href="mailto:"> help@datarebate.com</a> or call <a
                        href="tel:1-877-630-2345">1-877-630-2345</a>
                    </span> between 9:00am to 5:00pm EST Mon– Fri. Keep copies of all materials submitted. You will need to resubmit documents to complete validation if your claim is rejected. Originals become our property. Void where prohibited or restricted by law. If you provide us with a valid email address, we will send you status updates for this reimbursement submission. We and our agents do not sell or share customer names or emails to other companies for their marketing purposes.
                </p>

                <p className="pb-5 text-red-500 font-bold">
                    Rebate will be in the form of a Visa prepaid card. Card is issued by MetaBank®, Member FDIC, pursuant to a license from Visa U.S.A. Inc. Card cannot be redeemed for cash. Card can be used everywhere Visa debit cards are accepted. Card valid for up to 6 months. Unused funds will forfeit after the valid thru date. Card terms and conditions apply.
                </p>
            </div>

        </div>
    );
}



export default TermsAndConditionsBase;