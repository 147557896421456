import ContractorInHomeBase from "../contractorInHomeBase";

function BcContractorInHome() {
    return (
        <div className="lg:h-screen h-full flex flex-col">
            <div className="bg-stone-200 md:px-20 px-5 pb-20 flex flex-col flex-1">
                <div className="md:text-4xl text-2xl font-bold py-2.5">
                    Contractor Steam Humidifier Electrode Wire Programs
                </div>
            <div className="flex lg:flex-row flex-col-reverse content-center pt-5">
                <div className="m-auto lg:w-1/2 text-lg">
                    <p className="pt-5">
                        Per the terms and conditions of this program, the submission period for this program closed as of 7/1/23. <a
                        target="_" href="in-home/terms/">Terms and Conditions</a>.

                    </p>

                    <p className="pt-5">
                        <strong>IMPORTANT SAFETY RECALL ALERT:</strong> Research Products Corporation has determined a specific type of electrode wire with a “D” shaped connector may loosen causing arcing and/or overheating at the interface of the connector and canister, posing a fire hazard. The affected Steam Humidifiers were produced from June 2010 – January 2015. No other products are affected. Research Products Corporation voluntarily notified and is working with the U.S. Consumer Products Safety Commission on this issue.
                        To learn about the recall, please go to <a href="https://www.steamhumidifier.rpcrecall.com/" target="_"> RPCRecall.com/SteamHumidifier </a> or call  <a href="tel:1-888-742-2401">1.888.742.2401</a> (M-F 8 AM – 5 PM EST) to speak to a customer service agent.

                    </p>
                </div>
                <div className="mx-5 lg:w-1/2 lg:p-0 m-auto pt-5 pb-10 flex flex-col">
                    {
                        [
                            {
                                logoPath: '/carrier.png',
                                alt:'Carrier Logo'
                            },
                            {
                                logoPath: '/bryant.png',
                                alt:'Bryant Logo'
                            }
                        ].map(
                            (logo) =>
                                (
                                    <img alt={logo.alt} className="m-auto lg:max-w-md lg:py-10 max-w-xs sm:max-w-md  py-2.5" src={logo.logoPath}/>
                                )
                        )
                    }
                </div>
            </div>
        </div>

            <div className="bg-stone-200 md:px-20 px-5 pb-20 flex flex-col flex-1">
                <div className="flex lg:flex-row flex-col-reverse content-center pt-5">
                    <div className="m-auto lg:w-1/2 text-lg">
                        <p className="text-2xl font-medium"> Inventory Replacement Request </p>

                        <p className="pt-5 pb-2">

                                We will replace unused “D” shape connector electrode wire inventory with “O” shape connector wire inventory
                            Carrier/Bryant inventory part #5372 at no cost to you. Replacement requests must include a picture of the “D” shape connector wire inventory with the wire(s) clipped (cut). The completed form must be submitted online or post-marked on or before <strong>June 30, 2023</strong> central standard time. Allow 2 weeks for review and processing.
                        </p>

                    </div>
                    <div className="mx-5 lg:w-1/2 lg:p-0 m-auto pt-5 pb-10 flex flex-col">
                        {
                            [
                                {
                                    logoPath: '/carrier.png',
                                    alt:'Carrier Logo'
                                },
                                {
                                    logoPath: '/bryant.png',
                                    alt:'Bryant Logo'
                                }
                            ].map(
                                (logo) =>
                                    (
                                        <img alt={logo.alt} className="m-auto lg:max-w-md lg:py-10 max-w-xs sm:max-w-md  py-2.5" src={logo.logoPath}/>
                                    )
                            )
                        }
                    </div>
                </div>
<br/>
                <div className="pt-10">
                    <a
                        href="https://form.123formbuilder.com/6253810/"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4
            focus:outline-none focus:ring-blue-300 font-medium rounded-lg
            text-sm px-5 py-2.5 text-center inline-flex items-center
            dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
            no-underline
            visited:text-white
            ">
                        Continue to Form
                        <svg aria-hidden="true" className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                  clipRule="evenodd"></path>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default BcContractorInHome;