import ContractorTermsAndConditionsBase from "../contractorTermsAndCondtionsBase";

function BryantCarrierContractorTermsAndCondition() {
    return (
        <div>
            <ContractorTermsAndConditionsBase
                brand_string="Carrier, or Bryant branded"
                service_date="3/17/2024"
                submission_date="6/30/2023"
                processing_date_string="June 30, 2023"
            />
        </div>
    )
}

export default BryantCarrierContractorTermsAndCondition;