function ContractorInHomeBase({logos, form_url,
                                  incentive_amount = "50",
                                  submission_date = "6/30/2023" }) {
    return (
        <div className="main bg-stone-200 md:px-20 px-5 pb-20 flex flex-col">
            <div className="md:text-4xl text-2xl font-bold py-2.5">
                Contractor Steam Humidifier Electrode Wire Programs
            </div>

            <div className="flex lg:flex-row flex-col-reverse content-center pt-5">
                <div className="m-auto lg:w-1/2 text-lg">
                    <p className="text-2xl font-medium"> In Home Replacement </p>

                    <p className="pt-5">
                        Claim your ${incentive_amount} incentive for every pair steam humidifier electrode wires you replace.
                    </p>

                    <p className="pt-5 pb-2">
                        This offer is valid for contractors in the United States & Canada. Fill out form completely, and submit it along with:
                    </p>
                    <ul className="list-decimal list-inside pl-5 pb-5">
                        <li className="p-1">
                            Must provide a valid invoice for service work provided including the electrode wire replacements.
                        </li>
                        <li className="p-1">
                            Submissions must be completed on or before <strong>{submission_date}</strong>. You must submit your incentive program documents online to file your claim.
                        </li>
                    </ul>

                    <p className="pt-5">
                        Rebate will be in the form of a Visa prepaid card. Review <a href="in-home/terms" target="_">Terms and Conditions</a>.
                    </p>
                </div>
                <div className="mx-5 lg:w-1/2 lg:p-0 m-auto pt-5 pb-10 flex flex-col">
                    {
                        logos.map(
                            (logo) =>
                                (
                                    <img alt={logo.alt} className="m-auto lg:max-w-md lg:py-10 max-w-xs sm:max-w-md  py-2.5" src={logo.logoPath}/>
                                )
                        )
                    }
                </div>
            </div>

            <div className="pt-10">
                <a
                    href={form_url}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4
            focus:outline-none focus:ring-blue-300 font-medium rounded-lg
            text-sm px-5 py-2.5 text-center inline-flex items-center
            dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
            no-underline
            visited:text-white
            ">
                    Continue To Form
                    <svg aria-hidden="true" className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                              clipRule="evenodd"></path>
                    </svg>
                </a>
            </div>
        </div>
    )
}

export default ContractorInHomeBase;