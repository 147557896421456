import ContractorTermsAndConditionsBase from "../contractorTermsAndCondtionsBase";

function JohnsonContractorTermsAndConditions() {
 return (
      <div className="h-full">
       <ContractorTermsAndConditionsBase
       brand_string="York, Coleman, or Luxaire branded Johnson Controls"
       />
      </div>
 )
}

export default JohnsonContractorTermsAndConditions;