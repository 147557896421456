import TermsAndConditionsBase from "../termsAndConditionsBase";

function JohnsonTermsAndConditions() {
    return (
        <div>
            <TermsAndConditionsBase
                brand_string="York, Coleman, or Luxaire branded Johnson Controls"
            />
        </div>
    )
}

export default JohnsonTermsAndConditions;