function ContractorTermsAndConditionsBase({brand_string,
                                              service_date = "5/31/23",
                                              submission_date = "6/30/23",
                                              processing_date_string = "June 30, 2023"}) {

    let first_paragraph = "";

    if (brand_string.includes("Carrier")) {
        first_paragraph = `This Service Reimbursement offer applies only to the replacement of a pair of “D” shaped electrode wires with a pair of “O” shaped electrode wires, and replacement of the canister, for Research Products Steam Humidifiers produced from June 2010 to January 2015 under the brands AprilAire, Carrier, Bryant, York, Coleman and Luxaire. This offer is valid for contractors in the United States & Canada. Fill out this form completely, and submit along with:`
    }
    else {
        first_paragraph = `This Reimbursement offer applies to the replacement of the steam humidifier electrode wires for the ${brand_string} HUMXXSTM3034 Steam
                    Humidifier during the offer dates listed on this form.
                    This offer is valid for contractors in the United States & Canada.`
    }

    return (
        <div className="terms flex items-center justify-center
        h-screen scroll-auto bg-stone-200 pb-20 lg:pt-50 pt-20">
            <div className="content lg:w-3/6 w-5/6 py-20 lg:py-10">
                <p className="lg:text-3xl text-2xl font-bold pt-5">
                    TERMS AND CONDITIONS
                </p>

                <p className="lg:text-2xl text-xl font-semibold pt-5">
                    Research Products Contractor Service Reimbursement Program
                </p>

                <p className="pt-5">
                    {first_paragraph}
                </p>


                <p className="pt-5">
                    Fill out this form completely, and submit it along with:
                </p>
                <ul className="list-decimal list-inside pl-5">
                    <li className="p-1">
                        Valid photo of a pair of electrode wires showcasing the “D” shaped connector. The photo must be clear and identifiable. Unclear or blurred photos will not be accepted.
                    </li>
                    <li className="p-1">
                        Must provide a valid no-charge invoice for service work provided including the electrode wire and canister replacements. Must include the address where the service work was performed.
                    </li>
                </ul>

                <p className="pt-5">
                    <strong>Duplicate photos and invoices will be rejected.</strong> No substitutions or extensions. Research Products and its agents have the right to substantiate submissions and to reject claims that do not comply with these terms. Research Products is not responsible for lost, stolen, invalid or incomplete submissions. Research Products reserves the right to modify this reimbursement program at any time. Fraudulent submissions could result in federal prosecution under mail fraud statutes (Title 18 USC Sections 1341 & 1342).
                </p>

                <p className="pt-5">
                    Please allow up to 4 weeks for check delivery after service reimbursement documents have been received, processed and approved by Research Products.
                    <strong> For information or questions regarding the status of your service reimbursement claim, call <a href="tel:1-888-742-2401">1-888-742-2401</a></strong> between 8:00am and 5:00pm EST Mon-Fri. Keep copies of all materials submitted. You will need to resubmit documents to complete validation if your claim is rejected. Void where prohibited or restricted by law. Research Products and its agents do not sell or share customer names or emails to other companies for their marketing purposes.
                </p>

            </div>
        </div>
    )
}

export default ContractorTermsAndConditionsBase;