import ConsumerBase from "../consumerBase";

function BryantCarrierConsumer() {
    return(
        <div className="lg:h-screen h-full">
            <ConsumerBase
                brand_string="Carrier, or Bryant branded"
                logos={[
                    {
                        logoPath: '/carrier.png',
                        alt:'Carrier Logo'
                    },
                    {
                        logoPath: '/bryant.png',
                        alt:'Bryant Logo'
                    }
                ]}
                form_url="https://datarebate.com/carrier-bryant-homeowner/"
                service_date="5/31/23"
                submission_date="6/30/23"
            />
        </div>
    )
}

export default BryantCarrierConsumer;