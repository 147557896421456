function Main() {
    return(
        <div className="main bg-stone-200 h-full md:px-20 px-5 pb-20 flex flex-col justify-center items-center">
            <div className="md:text-4xl text-2xl font-bold pt-2.5 pb-10">Home Steam Humidifier Service Reimbursement Programs </div>

            <div className="text-center bg-stone-300 p-10 rounded-2xl">
                <p className="md:text-2xl text-xl font-bold"> Home Owner Reimbursement Programs </p>
                <div id="installer-branded-cards" className="flex lg:flex-row flex-col justify-center items-center flex-wrap ">
                    {
                        [
                            {
                                logo:'https://rp.widen.net/content/rdi5atvgv8/svg/aprilaire-blue-color-logo.svg',
                                url:'https://www.aprilaire.com/Homeowner-Reimbursement-Request',
                            },
                            {
                                logo:'/johnson-controls.png',
                                url:'https://jci.humidifiersafety.com',
                            },
                            {
                                logo:'/carrier.png',
                                url: 'https://rpc.humidifiersafety.com',
                            },
                        ].map((content) => (
                            <a href={content.url}
                               className="block lg:w-4/12 md:w-6/12 w-8/12 m-4 p-6 bg-stone-50 border border-gray-200 rounded-lg shadow-md hover:bg-gray-100">
                                <img className="m-auto lg:py-10 w-fit py-2.5 h-40"  src={content.logo}/>
                            </a>

                        ))
                    }
                </div>
            </div>

            <div className="text-center bg-stone-300 p-10 rounded-2xl mt-10">
                <p className="md:text-2xl text-xl font-bold"> Installer Reimbursement Programs </p>
                <div id="installer-branded-cards" className="flex lg:flex-row flex-col justify-center items-center flex-wrap ">
                    {
                        [
                            {
                                logo:'https://rp.widen.net/content/rdi5atvgv8/svg/aprilaire-blue-color-logo.svg',
                                url:'https://www.aprilairepartners.com/replacement-request',
                            },
                            {
                                logo:'/johnson-controls.png',
                                url:'https://jci.humidifiersafety.com/contractor/in-home',
                            },
                            {
                                logo:'/carrier.png',
                                url: 'https://rpc.humidifiersafety.com/contractor/in-home',
                            },
                        ].map((content) => (
                            <a href={content.url}
                               className="block lg:w-4/12 md:w-6/12 w-8/12 m-4 p-6 bg-stone-50 border border-gray-200 rounded-lg shadow-md hover:bg-gray-100">
                                <img className="m-auto lg:py-10 w-fit py-2.5 h-40"  src={content.logo}/>
                            </a>

                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Main;