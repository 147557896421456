import TermsAndConditionsBase from "../termsAndConditionsBase";

function BryantCarrierTermsAndConditions() {
    return (
        <div>
            <TermsAndConditionsBase
                brand_string="Carrier, or Bryant branded"
                service_date="5/31/2023"
                submission_date="6/30/2023"
                processing_date_string="June 30, 2023"
            />
        </div>
    )
}

export default BryantCarrierTermsAndConditions;