import ConsumerBase from "../consumerBase";


function JohnsonConsumer() {
    return(
        <div>
            <ConsumerBase
                brand_string="York, Coleman, or Luxaire branded Johnson Controls"
                logos={[
                    {
                        logoPath:  '/johnson-controls.png',
                        alt: 'Johnson Controls Logo'
                    },
                ]}
                form_url="https://datarebate.com/johnsoncontrols-homeowner/"
            />
        </div>
    )
}

export default JohnsonConsumer;